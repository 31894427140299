import React from 'react'
import {Helmet} from "react-helmet";
import {Link} from "gatsby";

import githubIcon from '../images/social/github.svg'
import twitterIcon from '../images/social/twitter.svg'


export const SiteLayout = ({children}) => {
  return (
    <div>

      <Helmet title="Porthou.se" defer={false}/>

      <a href="#main-content" className="sr-only focus:not-sr-only focus:mx-auto">
        Jump to Content
      </a>

      <header className={"container md:container py-4 pb-32 md:mx-auto flex justify-between items-center text-4xl"}>
        <h1>
          <Link to={"/"}>Porthou.se</Link>
        </h1>

        <ul className={`flex space-x-2 items-center`}>
          <li>
            <a
              href="https://twitter.com/port3m5"
              target="_blank"
              rel="nofollow noopener noreferrer">
              <img className={"h-16"} src={twitterIcon} alt="Twitter"/>
            </a>
          </li>
          <li>
            <a
              href="https://github.com/AnthonyPorthouse"
              target="_blank"
              rel="nofollow noopener noreferrer">
              <img className={"h-16"} src={githubIcon} alt="GitHub"/>
            </a>
          </li>
        </ul>
      </header>

      <main id="main-content" className={`container md:container md:mx-auto`}>
        <div className="mx-auto max-w-xl">
          {children}
        </div>
      </main>
    </div>
  )
}

export const getLayout = page => <SiteLayout>{page}</SiteLayout>

export default SiteLayout
